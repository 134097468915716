import axios from "axios";

const token = localStorage.getItem("user-token");
const headersFormData = {
  headers: {
    "Content-Type": `multipart/form-data;`,
    Authorization: `${token}`,
  },
};
const headers = { headers: { Authorization: `${token}` } };
const BASE_API = process.env.VUE_APP_BASE_API;
const name = "news";

export default {
  create(formData) {
    return axios.post(`${BASE_API}/${name}/create`, formData, headersFormData);
  },
  uploadFile(formData) {
    return axios.post(
      `${BASE_API}/${name}/uploadFile`,
      formData,
      headersFormData
    );
  },
  update(id, formData) {
    return axios.post(
      `${BASE_API}/${name}/update/${id}`,
      formData,
      headersFormData
    );
  },
  alterSpotlight(id) {
    return axios.post(`${BASE_API}/${name}/alterSpotlight/${id}`, {}, headers);
  },
  get(data) {
    return axios.post(`${BASE_API}/${name}/`, data);
  },
  paginate(data, page) {
    return axios.post(`${BASE_API}/${name}/paginate?page=${page}`, data);
  },
  delete(id) {
    return axios.post(`${BASE_API}/${name}/destroy/${id}`, {}, headers);
  },
  downloadFile(data) {
    return axios.post(`${BASE_API}/${name}/downloadFile`, data);
  },
};
