<template>
  <div>
    <!-- =======================================================
                                Overlay
    ============================================================-->
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        color="blue"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <!-- =======================================================
                                Dialog
    ============================================================-->
    <v-dialog v-model="openDialog" width="500">
      <v-card>
        <v-card-title class="headline red white--text" primary-title>
          <v-spacer />Atenção
          <v-spacer />
        </v-card-title>
        <v-card-text class="pa-5"
          >Deseja sair, todo o processo será encerrado</v-card-text
        >

        <v-divider></v-divider>

        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <v-btn text @click="openDialog = false">Não</v-btn>
          <v-spacer></v-spacer>
          <v-btn text to="/pages/news">Sim</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- =======================================================
                                Snackbar
    ============================================================-->
    <v-snackbar v-model="snackbar.active">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.active = false"
          >Cancelar</v-btn
        >
      </template>
    </v-snackbar>

    <!-- =======================================================
                                Main
    ============================================================-->
    <v-container id="createEditNews" fluid tag="section">
      <v-row justify="center">
        <v-col cols="10">
          <v-stepper v-model="stepper">
            <v-stepper-header>
              <v-divider></v-divider>
              <v-stepper-step :complete="stepperIndex > 1" step="1"
                >Dados</v-stepper-step
              >

              <v-divider></v-divider>
              <v-stepper-step :complete="stepperIndex > 2" step="2"
                >Post</v-stepper-step
              >

              <v-divider></v-divider>
            </v-stepper-header>

            <v-stepper-items class="stepper-items">
              <v-stepper-content step="1">
                <v-row justify="space-around">
                  <v-col cols="5">
                    <v-form>
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12">
                            <v-col cols="12">
                              <v-text-field
                                v-model="title"
                                label="Titulo da notícia"
                                outlined
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                v-model="category"
                                outlined
                                :items="categoryes"
                                :rules="[(v) => !!v || 'Escolha uma categoria']"
                                label="Categoria"
                                required
                              ></v-select>
                            </v-col>
                            <v-col cols="12">
                              <v-menu
                                v-model="menuPicker"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="computedDateFormatted"
                                    label="Data da postagem"
                                    persistent-hint
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @blur="date = parseDate(dateFormatted)"
                                    outlined
                                    required
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="date"
                                  locale="pt-br"
                                  no-title
                                  @input="menuPicker = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col class="d-flex align-center justify-center" cols="5">
                    <v-row>
                      <v-col
                        style="border: 1px solid #c9c9c9; max-width: 400px"
                      >
                        <card
                          :title="title"
                          :url="fileImgCardPreview"
                          :file="imgCard"
                          :category="categoryComputed"
                          :height="238"
                          @fileUrl="fileUrl"
                        />

                        <v-col
                          class="d-flex align-center justify-center"
                          cols="12"
                        >
                          <input
                            v-show="false"
                            accept="image/*"
                            ref="inputUpload"
                            type="file"
                            @change="onFileChange"
                          />
                          <v-btn
                            color="success"
                            @click="$refs.inputUpload.click()"
                            >{{
                              imgCard || fileImgCardPreview
                                ? "Trocar imagem"
                                : "Escolha uma imagem"
                            }}</v-btn
                          >
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-container
                  cols="12"
                  class="d-flex justify-space-between px-0"
                >
                  <v-btn text @click.prevent="cancel()">Cancelar</v-btn>
                  <v-btn color="primary" @click="next()">Próximo</v-btn>
                </v-container>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-row>
                  <v-col cols="12">
                    <v-container cols="12" class="d-flex justify-center">
                      <template>
                        <div class="editor-wrapper">
                          <vue-editor
                            useCustomImageHandler
                            v-model="content"
                            @imageAdded="handleImageAdded"
                          ></vue-editor>
                        </div>
                      </template>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="linkForm"
                      label="Link da pesquisa"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-container cols="12" class="d-flex justify-center">
                      <template>
                        <v-file-input
                          v-model="files"
                          accept=".csv,.xls,.xlsx,.txt,.pdf,.xml,.png,.jpg,.jpeg,.gif,.svg,.doc,.docx"
                          label="Adicionar arquivos"
                          @change="alterFiles()"
                          outlined
                        ></v-file-input>
                      </template>
                    </v-container>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="5" class="px-0">
                    <v-container>
                      <v-toolbar-title class="blue white--text pa-2 text-center"
                        >Lista de arquivos</v-toolbar-title
                      >
                      <v-card
                        class="mx-auto overflow-y-auto"
                        max-width="100%"
                        max-height="300"
                        height="300"
                        tile
                      >
                        <v-list :nav="true" :three-line="true">
                          <v-list-item-group color="primary">
                            <v-subheader v-if="filesAll.length === 0"
                              >Nenhum arquivo adicionado.</v-subheader
                            >

                            <v-list-item
                              v-for="(file, index) in filesAll"
                              :key="index"
                            >
                              <v-icon large class="mr-5" color="blue"
                                >mdi-file-upload-outline</v-icon
                              >
                              <v-list-item-content>
                                <v-list-item-subtitle
                                  v-html="file.name"
                                ></v-list-item-subtitle>
                              </v-list-item-content>

                              <v-btn
                                class="mx-2"
                                @click="removeFile(index)"
                                icon
                                dark
                                small
                                color="red"
                              >
                                <v-icon dark>mdi-delete</v-icon>
                              </v-btn>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-container>
                  </v-col>
                </v-row>
                <v-container
                  cols="12"
                  class="d-flex justify-space-between px-0"
                >
                  <v-btn text @click="stepper = 1">Voltar</v-btn>
                  <v-btn color="primary" @click="create()">Salvar</v-btn>
                </v-container>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import categoryService from "../../../../service/categoryService";
import newsService from "../../../../service/newsService";
import { VueEditor } from "vue2-editor";

export default {
  name: "CreateEditNews",
  components: {
    Card: () => import("../../../../components/commons/Card"),
    VueEditor,
  },
  data: (vm) => ({
    title: "",
    category: null,
    imgCard: "",
    imgCardoOld: "",
    menuPicker: false,
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    fileImgCardPreview: "",
    files: [],
    filesAll: [],
    fileRemove: [],
    overlay: false,
    content: "",
    linkForm: null,
    allUrlPost: [],
    openDialog: false,
    stepper: 1,
    stepperIndex: 1,
    categoryes: [],
    snackbar: {
      text: "",
      active: false,
    },
  }),
  methods: {
    alterFiles() {
      this.filesAll = this.filesAll.concat(this.files);
    },
    removeFile(index) {
      if (this.filesAll[index] && this.filesAll[index].id_news) {
        this.fileRemove.push(this.filesAll[index].id_news);
      }
      this.filesAll.splice(index, 1);
      this.files = this.filesAll;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${day.padStart(2, "0")}-${month.padStart(2, "0")}`;
    },
    fileUrl(url) {
      this.fileImgCardPreview = url;
    },
    cancel() {
      this.title || this.category || this.imgCard
        ? (this.openDialog = true)
        : this.$router.push({ path: "/pages/news" });
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("image", file);
      this.overlay = true;
      newsService
        .uploadFile(formData)
        .then((result) => {
          let url = result.data;
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();

          this.overlay = false;
        })
        .catch((err) => {});
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.imgCard = files[0];
    },
    getCategorys() {
      this.overlay = true;
      categoryService.get({}).then((res) => {
        this.categoryes = res.data.map((category) => {
          category.value = category.id;
          category.text = category.name;
          this.overlay = false;
          return category;
        });
      });
    },
    create() {
      const formData = new FormData();

      if (this.filesAll.length > 0) {
        this.filesAll.forEach((file) => {
          if (file && file.type) {
            formData.append("docs", file, file.name);
          }
        });
      }

      if (this.imgCard) {
        formData.append("image", this.imgCard, `card_${new Date().getTime()}`);
      }

      formData.append("title", this.title);
      formData.append("id_category", this.category);
      formData.append("content", this.formatPost(this.content));
      formData.append("date", this.formatDate(this.date));

      if (this.linkForm) {
        formData.append("link_form", this.linkForm);
      } else {
        formData.append("link_form", '');
      }

      if (this.id) {
        if (this.fileRemove.length > 0) {
          formData.append("fileRemove", JSON.stringify(this.fileRemove));
        }

        this.overlay = true;
        newsService
          .update(this.id, formData)
          .then((res) => {
            this.overlay = false;
            this.$router.push({ path: "/pages/news" });
          })
          .catch((error) => {
            this.overlay = false;
            this.snackbar.text = "Erro ao salvar a noticia.";
            this.snackbar.active = true;
          });
      } else {
        this.overlay = true;
        newsService
          .create(formData)
          .then((res) => {
            this.overlay = false;
            this.$router.push({ path: "/pages/news" });
          })
          .catch((error) => {
            this.overlay = false;
            this.snackbar.text = "Erro ao salvar a noticia.";
            this.snackbar.active = true;
          });
      }
    },
    next() {
      if (!this.title) {
        this.snackbar.text = "O titulo não pode ser vazio!";
        this.snackbar.active = true;
      } else if (!this.category) {
        this.snackbar.text = "A categoria não pode ser vazia!";
        this.snackbar.active = true;
      } else if (!this.imgCard && !this.fileImgCardPreview) {
        this.snackbar.text = "A foto do card não pode ser vazia";
        this.snackbar.active = true;
      } else {
        this.snackbar.text = "";
        this.snackbar.active = false;
        this.stepper = 2;
      }
    },
    formatPost(content) {
      let images = [];
      let regex = /<img src="([\w\W]+?)"/g;

      images = content.match(regex);

      if (images) {
        images.forEach((img) => {
          content = String(content).replace(
            '<img src="',
            '<img class="img-post" src="'
          );
        });
      }

      return content;
    },
  },
  computed: {
    categoryComputed() {
      const categoryes = this.categoryes.filter(
        (res) => res.id === this.category
      );
      if (categoryes && categoryes.length > 0) {
        return categoryes[0].text;
      }
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    editorContent: {
      get() {
        return this.formatPost(this.content);
      },
      set(updatedContent) {
        this.$emit("update:content", this.formatPost(updatedContent));
      },
    },
  },
  mounted() {
    this.id = parseInt(this.$router.history.current.params.id);

    this.getCategorys();
    if (this.id) {
      this.overlay = true;
      newsService.get({ id: this.id }).then((res) => {
        const data = res.data;
        this.title = data[0].title;
        this.category = data[0].id_category;
        this.date = this.parseDate(data[0].date);
        this.fileImgCardPreview = data[0].path_img_card;
        this.content = data[0].content;
        this.linkForm = data[0].link_form;
        this.overlay = false;

        data.forEach((news) => {
          const { docs = "" } = news;
          const docsParsed = JSON.parse(docs);

          const files = docsParsed.map((doc) => ({
            path: doc.path,
            name: doc.name,
            id_news: doc.id_news,
          }));

          this.filesAll.push(...files);
        });
      });
    }
  },
};
</script>

<style lang="sass">
.editor
  max-width: 100%
  width: 100%
  max-height: 28rem
  min-height: 28rem

.img-post
  max-width: 100%
  background-position: center

.ql-editor
  max-height: 360px !important
  height: 360px !important
  overflow: auto !important

.card-img
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  height: 100%
  width: 100%
</style>
